import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import roleOrServices from "./content/roleorservice.json";
import RoleOrServiceCard from "./roleorservicecard";

export default function HireMe() {
  return (
    <>
      <>
        <Container fluid>
          <Row className="mt-3 mb-3 mx-3">
            <Col lg={9} className="mt-3 msb-2">
              <h1 className="text-accent1">Hire me</h1>
              <p className="text-accent3">
                I offer fractional and interim leadership services that 
                help organizations navigate change, scale effectively, and
                strengthen their foundations. 
              </p>
              <p className="text-accent3">
                Drawing on my experience with
                building high-performing teams, leading or streamlining delivery, 
                and supporting leaders, I bring a steady hand and a fresh
                perspective to complex challenges. Whether supporting leadership
                transitions, aligning teams with strategic goals, or enabling
                sustainable growth, my focus is always on creating lasting,
                tangible impact addressing your specific needs.
              </p>
              <p className="text-accent3">Please consider the following options or <a href="./connect">contact me</a> to directly discuss</p>
            </Col>
            <Col lg={3}>
              <div className="text-center mt-3 mb-3">
                <img
                  src="./images/pexels-pixabay-276272.jpg"
                  alt="Orchestrating Transitions"
                  className="circular-image"
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-1 ms-1">
            {roleOrServices.map((roleOrService, index) => (
              <Col 
                data-testid="roleorservice-card"
                sm={12}
                md={7}
                lg={4}
                className="mb-5"
                key={"roleorservice_" + index}
              >
                <RoleOrServiceCard data={roleOrService}  />
              </Col>
            ))}
          </Row>
        </Container>
      </>
    </>
  );
}
