import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function AboutMe() {
  return (
    <Container fluid className="bg-light text-accent1 full-height ">
      <Row className="justify-content-center">
        <Col lg={2} sm={12} className="px-4">
          <div className="text-center mt-3 mb-3">
            <img
              src="./images/rick_1024.jpg"
              alt="Introduction"
              className="circular-image"
            />
          </div>
          <h3>Nice to meet you</h3>
          <p>
            I’m Rick, an experienced software engineering leader with a diverse
            background that spans software engineering, consulting, and
            executive roles.
          </p>
          <p>
            I am available for fractional and interim leadership roles, keen to
            help organizations navigate change, scale effectively, and
            strengthen their foundations.
          </p>
        </Col>
        <Col lg={2} sm={12} className="px-4">
          <div className="text-center mt-3 mb-3">
            <img
              src="/images/code-1000.jpg"
              alt="Origin"
              className="circular-image"
            />
          </div>
          <h3>Technical Roots</h3>
          <p>
            Though my career has evolved beyond hands-on coding, my passion for
            technology remains strong. My background in software engineering helps me to bring technical
            expertise to my leadership roles. I leverage my knowledge to bridge
            the gap between technical and non-technical stakeholders, ensuring
            that decisions are both strategic and technically sound.
          </p>
        </Col>
        <Col lg={2} sm={12} className="px-4">
          <div className="text-center mt-3 mb-3">
            <img
              src="./images/pexels-taryn-elliott-4253928.jpg"
              alt="Values"
              className="circular-image"
            />
          </div>
          <h3>Going Global</h3>
          <p>
            My personal journey has taken me from the Netherlands to Australia,
            Ireland, France and South Africa. My career has given me the chance
            to work with teams in different parts of the world across various industries.
            These experiences have helped me adapt to different environments and
            understand a variety of organizational and cultural contexts.
          </p>
        </Col>
        <Col lg={2} sm={12} className="px-4">
          <div className="text-center mt-3 mb-3">
            <img
              src="./images/rick_goto2024.jpg"
              alt="Journey"
              className="circular-image"
            />
          </div>
          <h3>People-Centric Leadership</h3>
          <p>
            I aim to create a supportive and collaborative environment where
            leaders and teams feel heard and valued. By drawing on my technical
            background and leadership experience, I try to offer practical
            guidance that aligns with their specific challenges. My focus is on
            listening carefully, encouraging open discussions, and working
            together to find the right solutions without overcomplicating
            things.
          </p>
        </Col>
        <Col lg={2} sm={12} className="px-4">
          <div className="text-center mt-3 mb-3">
            <img
              src="./images/pexels-pixabay-276272.jpg"
              alt="Orchestrating Transitions"
              className="circular-image"
            />
          </div>
          <h3>Orchstrating Transitions.... </h3>
          <p>
            Drawing on my experience with building high-performing teams,
            leading or streamlining delivery, and supporting leaders, I bring a
            steady hand and a fresh perspective to complex challenges. Whether
            supporting leadership transitions, aligning teams with strategic
            goals, or enabling sustainable growth, my focus is always on
            creating lasting, tangible impact addressing your specific needs.
          </p>
        </Col>
      </Row>
      <Row className="text-center">
        <Col className="mb-5">
          <a
            href={"/connect"}
            className="btn btn-primary mt-auto font-weight-bold"
          >
            Lets connect
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default AboutMe;
