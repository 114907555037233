import React from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import roleOrServicesData from "./content/roleorservice.json";
import differentiatorData from "./content/differentiators.json";
import benefitData from "./content/benefits.json";
import relevantExperienceData from "./content/relevantexperience.json";
import { showInParagraphs } from "./utils";

export default function RoleOrService() {
  const { id } = useParams(); // Extract the ID from the URL
  const roleOrServiceData = roleOrServicesData.find(
    (roleOrService) => roleOrService.title === id
  );
  const differentiatorTitles = roleOrServiceData.differentiators.split(",");
  const benefits = roleOrServiceData.benefits.split(",");
  const relevantExperiences = roleOrServiceData.relevantExperience.split(",");
  
  // for each of the differentiators, find the description
  const differentiatorDescriptions = differentiatorTitles.map((differentiator) => {
    const description = differentiatorData.find(
      (data) => data.Title === differentiator
    );
    return description ? description.Description : "";
  });
  // for each of the benefits, find the description
  const benefitDescriptions = benefits.map((benefit) => {
    const description = benefitData.find(
      (data) => data.Title === benefit.trim()
    );
    return description ? description.Description : "";
  });
  // for each of the relevant experiences, find the description
  const relevantExperiencesDescriptions = relevantExperiences.map(
    (relevantExperience) => {
      const description = relevantExperienceData.find(
        (data) => data.title === relevantExperience
      );
      return description ? description.description : "";
    }
  );

  //create an array of cards with all benefits, differentiators, and achievements
  const cards = [];
  benefits.forEach((benefit, index) => {
    cards.push([benefit, benefitDescriptions[index]]);
  });
  differentiatorTitles.forEach((differentiator, index) => {
    cards.push([differentiator, differentiatorDescriptions[index]]);
  });
  //console.log(relevantExperiences)
  relevantExperiences.forEach((relevantExperience, index) => {
    cards.push([relevantExperience, relevantExperiencesDescriptions[index]]);
  });

  return (
    <>
      <Container fluid className="bg-light">
        <Container>
          <Row className="d-flex">
            <Col lg={9}>
              <h1 className="text-info">{roleOrServiceData.title}</h1>
              <div className="text-accent1">
                {showInParagraphs(roleOrServiceData.description)}
              </div>
            </Col>
            <Col lg={3} className="mb-2 mt-2">
              <Card>
                <Card.Img src={"../images/" + roleOrServiceData.image} />
              </Card>
            </Col>
          </Row>
        </Container>
        <Row className="text-center">
          <Col>
            <h2 className="text-info">Benefits</h2>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          {benefits.map((benefit, index) =>
            createCard(index, benefit, benefitDescriptions[index])
          )}
        </Row>
        <Row className="text-center">
          <Col>
            <h2 className="text-info">Why work with me</h2>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          {differentiatorTitles.map((differentiator, index) =>
            createCard(index, differentiator, differentiatorDescriptions[index])
          )}
        </Row>
        <Row className="text-center">
          <Col>
            <h2 className="text-info">Relevant experience</h2>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          {relevantExperiences.map((relevantExperience, index) =>
            createCard(index, relevantExperience, relevantExperiencesDescriptions[index])  
          )}
        </Row>
        <Row className="text-center">
          <Col className="mb-5">
            <a
              href={"/connect"}
              className="btn btn-primary mt-auto font-weight-bold"
            >
              Lets connect
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );

  function createCard(index, title, description) {
    return (
      <Col sm={12} md={6} lg={3} className="text-left mb-4" key={index + 1}>
        <Card className="h-100">
          <Card.Body className="d-flex flex-column">
            <Card.Title className="text-accent2">{title}</Card.Title>
            <div className="flex-grow-1">{showInParagraphs(description)}</div>
          </Card.Body>
        </Card>
      </Col>
    );
  }
}
